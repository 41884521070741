import React, { ReactChild } from "react"
import { Link } from "gatsby"
import ScrollIntoView from 'scroll-into-view';
import cc from 'classcat';

import styles from './styles.module.scss';

type Props = {
    link: string,
    enquire?: boolean,
    children: ReactChild
}

export default class Button extends React.Component<Props> {
    handleClick = () => {
        // @ts-ignore
        ScrollIntoView(document.getElementById(this.props.link.replace('#', '')));
    }

    render() {
        const { link, children } = this.props;
        return (
            link[0] === "#" ? (
                <button
                    className={cc({
                        [styles.btn]: true,
                        [styles.enquire]: this.props.enquire
                    })}
                    onClick={this.handleClick}>
                    {children}
                </button>
            ) : (
                <Link className={styles.btn} to={link}>{children}</Link>
            )
        )
    }
}
