import React from "react"
import styles from './styles.module.scss';

const Overlay = ({ isOpen, toggleOpen }: { isOpen: boolean, toggleOpen: Function }) => (
  <div className={styles.overlay} data-active={isOpen}>
    <nav>
      <a href="/rates">Rates</a>
      <a href="/parents">Parents</a>
      <a href="/gallery">Gallery</a>
      <a href="/team">Meet our Team</a>
      <div>
        <a className={styles.button} href="#enquire" onClick={() => toggleOpen()}>ENQUIRE NOW</a>
      </div>
    </nav>
  </div>
)

export default Overlay
