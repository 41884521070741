import { Link } from "gatsby"
import React from "react"

import { Button } from 'components';

import styles from './styles.module.scss';

type Props = {
    siteTitle: string
}

const Header = ({ siteTitle }: Props) => (
  <header className={styles.desktopHeader}>
    <div>
      <h1>
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
    </div>
    <div>
      <nav>
        <Button link="/rates">Rates</Button>
        <Button link="/parents">Parents</Button>
        <Button link="/gallery">Gallery</Button>
        <Button link="/team">Meet our Team</Button>
        <Button link="/#enquire" enquire>ENQUIRE NOW</Button>
      </nav>
    </div>
  </header>
)

export default Header
