import { Link } from "gatsby"
import React, { useState } from "react"

import Overlay from './Overlay';
import Logo from 'images/logo.png';

import styles from './styles.module.scss';

type Props = {
    siteTitle: string
}

const MobileHeader = ({ siteTitle }: Props) => {
  const [active, setActive] = useState(false);
  return (
    <header className={styles.mobileHeader}>
      <Overlay isOpen={active} toggleOpen={() => setActive(!active)}/>
      <div className={styles.title}>
        <h1>
          <img src={Logo} />
          <Link to="/">
            {siteTitle}
          </Link>
        </h1>
      </div>
      <button
        className={`hamburger hamburger--elastic ${active && 'is-active'}`}
        type="button"
        onClick={() => setActive(!active)}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    </header>
  );
}

export default MobileHeader
